import React, { Component } from 'react';
import Avatar from '../../components/mikro/Avatar';
import { getIndex } from '../../utils/api';
import ReactPaginate from 'react-paginate'

class CardSponsor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            total: 0,
            current_page: 0,
            last_page: 0,
            per_page: 0,

        };
    }
    componentDidMount() {
        this.getProposal();
    }
    getProposal() {
        getIndex("proposal/"+this.props.data.id+"/sponsor", {
            ...this.state.filter,
            page: this.state.current_page,
        }).then((res) => {
            this.setState({ 
                items: res && res.data || null,
                total: res && res.meta && res.meta.total || 0,
                current_page: res && res.meta && res.meta.current_page || 0,
                last_page: res && res.meta && res.meta.last_page || 0,
                per_page: res && res.meta && res.meta.per_page || 0,
            });
        });
    }

    handlePageClick = (data) => {
        const page = data.selected >= 0 ? data.selected + 1 : 0;
        this.setState(
        {
            current_page: page,
        },
        () => this.getProposal()
        );
    };

    render() { 
        const {data} = this.props
        const {items,total,current_page,last_page,per_page,} = this.state

        return data &&  items && items.length > 0 && <div className="card mb-2">
          <div className="card-header">
            <h4 className="card-title mb-0 font-weight-bold">Respon Sponsor </h4>
          </div>
          <div className="card-content">
            <div className="table-responsive">
              <table className="table table-hover table-lg mb-0">
                <thead>
                  <tr>
                    <th className="border-top-0" style={{width:"3%"}}>No</th>
                    <th className="border-top-0" >Sponsor</th>
                    <th className="border-top-0" >Nama PIC</th>
                    <th className="border-top-0" >WA/HP PIC</th>
                    <th className="border-top-0" >Email PIC</th>
                    <th className="border-top-0 text-center" >Rejected / Follow Up</th>
                  </tr>
                </thead>
                <tbody>
                  { items.map((d,i) => <tr key={'proposal'+i}>
                    <td className="align-middle">{ ((current_page - 1) * per_page) + i + 1}</td>
                    <td>
                      <div className="d-flex flex-row align-items-center">
                      <Avatar src={d.corporate_thumbnail || ''}/> 
                      <span>{d.corporate_name}</span>
                      </div>
                    </td>
                    <td className='align-middle text-left '>{d.pic_name}</td>
                    <td className='align-middle text-left '>{d.pic_phone_number}</td>
                    <td className='align-middle text-left '>{d.pic_email}</td>
                    <td className='align-middle text-center font-weight-bold'><span className={d.status === 'REJECTED' ? 'text-danger':'text-success'}>{d.status || ''}</span></td>
                  </tr>)}
                </tbody>
              </table>
            </div>

            <div className="text-center w-100 px-2 ">
                <ReactPaginate
                  previousLabel={"Sebelumnya"}
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextLabel={"Selanjutnya"}
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel={"..."}
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  marginPagesDisplayed={2}
                  onPageChange={this.handlePageClick}
                  pageRangeDisplayed={per_page}
                  pageCount={last_page}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                />
              </div>
          </div>
        </div>
    }
}
 
export default CardSponsor;