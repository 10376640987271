import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Helmet from "react-helmet";
import { screenOrientation } from "../../utils/DeviceDetector";
import LogoLink from "./../../components/mikro/LogoLink";
import AuthIlustration from "./../../components/mikro/AuthIlustration";
import ReactInputVerificationCode from "react-input-verification-code";
import { authentication } from "../../utils/firebase-config.js";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

import {useDispatch}  from 'react-redux';
import { post_check_user_exist, post_check_mail_otp, post_set_token_reset_password } from "../../services/auth_service";
import toastr from "../../utils/toastr";

const LupaPassword = props => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [email, set_email ] = useState("")
  const [phone, set_phone ] = useState("")
  const [datatype, set_datatype ] = useState("phone")
  const [inputOtp, set_inputOtp ] = useState(false)
  const [otp, set_otp ] = useState(null)
  const [token, set_token ] = useState("")
  const [error_message, set_error_message ] = useState("")


  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // onSignInSubmit();
        },
      },
      authentication
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var error_message = "";
    if (datatype === "email") {
      if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        error_message = "Email tidak sesuai";
      }
    }

    if (datatype === "phone") {
      if (phone.toString().length < 8)
        error_message = "Nomor Handphone tidak boleh kurang dari 9 digit";
      else if (phone.toString().length > 14)
        error_message = "Nomor Handphone tidak boleh lebih dari 14 digit";
    }

    if (error_message === "") {
      set_error_message(error_message)
      if (datatype === "phone") sendPhoneOTP();
      else sendEmailOTP();
    } else {
      set_error_message(error_message)
    }
  }

  const sendPhoneOTP = () => {
    dispatch(post_check_user_exist({
      field: datatype,
      value: "0" + phone,
    },(res)=>{
      if (res.code === 200) {
        generateRecaptcha();
        let appVerifier = window.recaptchaVerifier;
        signInWithPhoneNumber(authentication, "+62" + phone, appVerifier)
          .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;

            set_inputOtp(true)
          })
          .catch((error) => {
            console.log("signin phone error", error);
          });
      } else{
        set_inputOtp(true);
        toastr(res.status,res.message)
      }
    }))
  }

  const sendEmailOTP = () => {
    dispatch(post_check_user_exist({
      field: datatype,
      value: email,
    },(res)=>{
      if (res.status === "success") {
        set_inputOtp(true);
      } else{
        set_inputOtp(true);
        toastr(res.status,res.message)
      }
    }))
  }

  const handleChange = (e) => {
    let { value, name } = e.target;

    const number = /^[0-9\b]+$/;
    if (name === "phone" && (value === "" || number.test(value))) {
      
      value = String(value).trim();
      
      if (value.startsWith("62")) {
        value = value.slice(2);
      } else if (value.startsWith("0")) {
        value = value.slice(1);
      }

      set_phone(value)
      set_error_message("")
    } else if (name === "email") {
      set_email(value)
      set_error_message("")
    }
  }

  const handleTabs = (datatype) => {
    set_datatype(datatype)
    set_error_message("")
  }

  const verifyOtp = (otp) => {
    const number = /^[0-9\b]+$/;
    if (number.test(otp) && otp.toString().length === 6) {

      if (datatype === "phone") {
        let confirmationResult = window.confirmationResult;
        if (confirmationResult) {
          confirmationResult
            .confirm(otp)
            .then((result) => {
              // User signed in successfully.
              const user = result.user;
              if (user) {
                setToken()
              }
            })
            .catch((error) => {
              set_error_message("Kode OTP Salah")
            });
        } else {
          set_error_message("Kode OTP Tidak Berlaku")
        }
      } else if (datatype === "email") {
        dispatch(post_check_mail_otp({
          email: email,
          otp: otp,
        },(res)=>{
          if (res.code === 200)
            setToken()
          else
            set_error_message("Kode OTP Tidak Berlaku")
        }))
      }
    }
  };

  const setToken = () => {
    dispatch(post_set_token_reset_password({
      field: datatype,
      value: datatype === 'phone' ? '0' + phone : email,
    },(res) => {
      if (res.code === 200) {
        history.push("/reset-password/" + res.data.token)
      } else {
       toastr(res.status,res.message)
      }
    }))
  }
  

  return (
    <>
   
      <Helmet
        bodyAttributes={{
          style: "padding-top: 0!important",
        }}
      />
      <div className="d-flex w-100" style={{ height: "100vH" }}>
        <div className="login-bg h-100">
          <AuthIlustration/>
        </div>
        <div className="login-form bg-white overflow-auto  m-0">
          <div
            className={
              " row h-100 w-100 py-2  m-0 align-items-center justify-content-center " +
              (screenOrientation() !== "portrait" ? " px-5" : "px-1")
            }
          >
            <div className="p-2 col-md-12 text-center">
              <LogoLink width="150px" />
            </div>
            <div className="p-2 col-md-12">
              <form
                className="form-horizontal form-simple"
                onSubmit={(e) => handleSubmit(e)}
              >
                <h2
                  className="text-center font-weight-bold mb-2"
                  style={{ color: "#474955" }}
                >
                  {inputOtp ? "Masukkan Kode OTP" : "Lupa Password ?"}
                </h2>
                <p className="text-center">
                  {inputOtp
                    ? "Masukkan kode OTP dari SMS atau Email Anda"
                    : "Masukkan No.Handphone atau Email Anda yang terdaftar di akun untuk Reset Password"}
                </p>
                {!inputOtp ? (
                  <ul
                    className="nav nav-tabs nav-underline no-hover-bg nav-justified border-bottom-0"
                    style={{
                      borderRadius: "0",
                      marginBottom: "2rem",
                    }}
                  >
                    <li
                      className="nav-item"
                      onClick={() => handleTabs("phone")}
                    >
                      <a className={"nav-link " + (datatype === "phone" ? "active" : "")}>
                        No.Handphone
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() => handleTabs("email")}
                    >
                      <a className={"nav-link " + (datatype === "email" ? "active" : "")}>
                        Email
                      </a>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
                {!inputOtp ? (
                  datatype === "email" ? (
                    <div
                      className="input-group"
                      style={{ marginBottom: "2rem" }}
                    >
                      <div className="input-group-prepend">
                        <span className="input-group-text  bg-white fs-16px  px-2">
                          <small>@</small>
                        </span>
                      </div>
                      <input
                        type="text"
                        className={
                          "form-control px-2  form-control-auth " +
                          (error_message !== "" && error_message !== null
                            ? "is-invalid"
                            : "")
                        }
                        name="email"
                        placeholder="contoh@gmail.com"
                        value={email}
                        onChange={(e) => handleChange(e)}
                      />
                      {error_message !== "" && error_message !== null ? (
                        <div className="invalid-feedback text-center">
                          {error_message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div
                      className="input-group"
                      style={{ marginBottom: "2rem" }}
                    >
                      <div className="input-group-prepend">
                        <span className="input-group-text  bg-white fs-16px  px-1-2">
                          <small>+62</small>
                        </span>
                      </div>
                      <input
                        type="text"
                        className={
                          "form-control px-2  form-control-auth " +
                          (error_message !== "" && error_message !== null
                            ? "is-invalid"
                            : "")
                        }
                        name="phone"
                        placeholder="8123456789"
                        value={phone}
                        onChange={(e) => handleChange(e)}
                      />
                      {error_message !== "" && error_message !== null ? (
                        <div className="invalid-feedback text-center">
                          {error_message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )
                ) : (
                  <div className="custom-styles mb-3">
                    <ReactInputVerificationCode
                      length={6}
                      placeholder=""
                      onChange={(e) => verifyOtp(e)}
                    />
                    {error_message !== "" && error_message !== null && (
                      <div className="d-block invalid-feedback text-center">
                        {error_message}
                      </div>
                    )}
                  </div>
                )}
                {!inputOtp ? (
                  <button
                    type="submit"
                    className="btn btn-warning btn-block mb-2 py-1"
                  >
                    <span>
                      Kirim Kode OTP ke{" "}
                      {datatype === "phone" ? "No.Handphone" : "Email"}
                    </span>
                  </button>
                ) : (
                  ""
                )}
                <div id="recaptcha-container"></div>
              </form>

              <div className="form-group text-center">
                <Link
                  to="/"
                  className="card-link font-weight-bold"
                >
                  <i className="las la-arrow-left"></i> Kembali
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


export default LupaPassword;