import React, { Component, Fragment, useState } from "react";
import Helmet from "react-helmet";
import { Link, useHistory} from "react-router-dom";
import { toast } from "react-toastify";

import { screenOrientation } from "../../utils/DeviceDetector";
import LogoLink from "./../../components/mikro/LogoLink";
import AuthIlustration from "./../../components/mikro/AuthIlustration";
import {EyeIcon,EyeSlashIcon} from "./../../components/mikro/Icon";
import { post_login } from "../../services/auth_service";
import { useDispatch } from "react-redux";
export const Masuk = () => {
  
  const history = useHistory()
  const dispatch = useDispatch()
  
  const [spinner, set_spinner] = useState(false)
  const [showPassword, set_showPassword] = useState(false)
  const [email, set_email] = useState('')
  const [password, set_password] = useState("")
  const [error_message, set_error_message] = useState("")

  const handleSubmit = (e) => {
    e.preventDefault();
    let _error_message = "";
    let type = "email";
    let emailUser = String(email).trim();
    if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      _error_message = "Email tidak sesuai";


      if (emailUser.startsWith("+62")) {
        emailUser = emailUser.slice(3);
        _error_message = "";
        type = "phone";
      } else if (emailUser.startsWith("62")) {
        emailUser = emailUser.slice(2);
        _error_message = "";
        type = "phone";
      } else if (emailUser.startsWith("0")) {
        emailUser = emailUser.slice(1);
        _error_message = "";
        type = "phone";
      } else {
        const number = /^[0-9\b]+$/;
        if (number.test(emailUser)) {
          type = "phone";
          _error_message = "";
        }
      }

      if (type === "phone") {
        if (emailUser.toString().length < 8)
          _error_message = "Nomor Handphone tidak boleh kurang dari 9 digit";
        else if (emailUser.toString().length > 14)
          _error_message = "Nomor Handphone tidak boleh lebih dari 14 digit";
        else _error_message = "";
      }
    }

    if(_error_message === "")
      dispatch(post_login({
        type: type,
        email: emailUser,
        password: password,
      },(res)=>{
        console.log(res)
        history.push('/app/beranda')
      }))
    else
      set_error_message(_error_message)
  }

  return (
    <Fragment>
      <Helmet
        bodyAttributes={{
          style: "padding-top: 0!important",
        }}
      />
      <div className="d-flex w-100" style={{ minHeight: "100vH" }}>
        <div className="login-bg h-100"  style={{ minHeight: "100vH" }}>
          <AuthIlustration/>
        </div>
        <div className="login-form bg-white m-0" style={{ minHeight: "100vH" }}>
          <div
            className={
              " row h-100 w-100 py-2 m-0 align-items-center justify-content-center " +
              (screenOrientation() !== "portrait" ? " px-5" : "px-1")
            }
          >
            <div class="p-2 col-md-12 text-center">
              <LogoLink width="150px" />
            </div>
            <div class="p-2 col-md-12">
              <h2
                className="text-center font-weight-bold mb-3"
                style={{ color: "#474955" }}
              >
                Login
              </h2>
              <form className="form" onSubmit={(e) => handleSubmit(e)}>
                <div class="mb-2">
                  <input
                    type="text"
                    className="form-control px-2 form-control-auth"
                    placeholder="Email atau Nomor Handphone"
                    name="email"
                    value={email}
                    onChange={(e) => set_email(e.target.value)}
                    required
                    autoComplete="off"
                    autoCorrect="off"
                    disabled={spinner}
                  />
                  {error_message && <small className="text-danger">{error_message}</small>}
                </div>
                <div class="input-group mb-3">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control px-2 form-control-auth"
                    name="password"
                    value={password}
                    minLength="6"
                    maxLength="50"
                    placeholder="Kata Sandi"
                    onChange={(e) => set_password(e.target.value)}
                    autoComplete="off"
                    required
                    disabled={spinner}
                  />
                  <div
                    class="input-group-append bg-white pointer "
                    onClick={() =>set_showPassword(!showPassword)}
                  >
                    <span class="input-group-text bg-white fs-16px  px-1-2">
                      {showPassword ? (
                        <EyeIcon/>
                      ) : (
                        <EyeSlashIcon/>
                      )}
                    </span>
                  </div>
                </div>
                <div className="form-group m-0">
                  <button
                    type="submit"
                    className="btn btn-warning btn-lg btn-block"
                    disabled={spinner}
                  >
                    {spinner === true ? (
                      <i className="la la-refresh spinner">

                      </i>
                    ) : (
                      <b>Masuk</b>
                    )}
                  </button>
                </div>
              </form>
            </div>
            <div class="p-2 col-md-12 mb-3">
              <p className="text-center">
                <Link
                  to="lupa-password"
                  className="card-link fs-14px font-weight-bold "
                >
                  Lupa Password ?
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Masuk;