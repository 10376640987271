import React, { Component, Fragment } from "react";

//table
import Filter from "./Filter";
import Form from "./Form";
import EmptyPage from "../../components/plugin/EmptyPage";

//// MODEL
import { getIndex } from "../../utils/api";

import ContentHeader from "../../components/mikro/ContentHeader";
import ReactPaginate from "react-paginate";

export default class Accounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Kelola Admin",
      isOpenPanel: false,

      filter: {
        paginate: true,
        search: "",
      },

      id: "",
      items: {
        data: [],
        total: 0,
        current_page: 0,
        last_page: 0,
        per_page: 0,
      },
    };
  }
  componentDidMount() {
    this.getIndex();
  }
  getIndex() {
    getIndex("admin", {
      ...this.state.filter,
      page: this.state.items.current_page,
    }).then((res) => {
      let items = {
        data: res.data.data || '',
        total: res.data.meta.total || 0,
        current_page: res.data.meta.current_page || 0,
        last_page: res.data.meta.last_page || 0,
        per_page: res.data.meta.per_page || 0,
      };
      this.setState({
        items: items,
      });

      window.scrollTo(0, 0);
    });
  }

  handleFilter(e) {
    let { filter } = this.state;
    this.setState({ filter:{...filter,...e} }, () => this.getIndex());
  }

  handlePageClick = async (data) => {
    const page = data.selected >= 0 ? data.selected + 1 : 0;
    this.setState(
      {
        items: {
          ...this.state.items,
          current_page: page,
        },
      },
      () => this.getIndex()
    );
  };

  fEdit(id) {
    this.setState({
      id: id,
      isOpenPanel: true,
    });
  }

  handleFormResponse(e) {
    this.setState(
      {
        id: e.id,
      },
      () => this.getIndex()
    );
  }

  render() {
    const { items, title, id, isOpenPanel } = this.state;
    return (
      <Fragment>
        <ContentHeader title={title}>
          <button
            className="btn btn-info float-right"
            onClick={() => this.setState({ isOpenPanel: true, id: "" })}
          >
            Tambah Admin
          </button>
        </ContentHeader>
        <div className="content-header row">
          <div className="content-header-left col-12 mb-1 breadcrumb-new">
            <Filter onChangeValue={(e) => this.handleFilter(e)} />
          </div>
        </div>
        <div className="content-body">
          <div className="row">
            <div className="col">
              {parseInt(this.state.items.total) > 0 ? (
                <div>
                  <div className="table-responsive">
                    <table className="table table-hover table-lg table-hover">
                      <thead className="bg-f4">
                        <tr>
                          <th width="50px">No</th>
                          <th>Nama</th>
                          <th>Username</th>
                          <th>Email</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.items.data.map((d, index) => (
                          <tr
                            className="pointer"
                            key={index}
                            onClick={() => this.fEdit(d.id)}
                          >
                            <td className="text-right">{index + items.current_page}</td>
                            <td>{d.name}</td>
                            <td>{d.username}</td>
                            <td>{d.email}</td>
                            <td className="text-center">
                              <span
                                className={
                                  "badge badge-" +
                                  (d.status === "ACTIVE" ? "success" : "secondary")
                                }
                              >
                                {d.status}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="text-center w-100">
                    <ReactPaginate
                      previousLabel={"Sebelumnya"}
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextLabel={"Selanjutnya"}
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel={"..."}
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      marginPagesDisplayed={2}
                      onPageChange={this.handlePageClick}
                      pageRangeDisplayed={items.per_page}
                      pageCount={items.last_page}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                    />
                  </div>
                </div>
              ) : (
                <div className="mt-5">
                  <EmptyPage text={true} />
                </div>
              )}
            </div>
          </div>
        </div>
        <Form
          id={id}
          isOpen={isOpenPanel}
          title={title}
          onClose={() =>
            this.setState({ isOpenPanel: false, id: "" }, () => this.getIndex())
          }
          onChangeValue={(e) => this.handleFormResponse(e)}
        />
      </Fragment>
    );
  }
}
