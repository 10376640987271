import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const CardWidgetTotal = ({title, total, color, ...props}) => {
    return (
        <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="card pull-up mb-2">
                <div className="card-content">
                    <div className="card-body">
                        <div className="media d-flex">
                            <div className="media-body text-left">
                                <h3 className={color}>
                                    <NumberFormat value={total} displayType={'text'} thousandSeparator={true}  />
                                </h3>
                                <h6>{title}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default CardWidgetTotal;