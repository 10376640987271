import React, { Component } from "react";
import Slider from "react-slide-out";

// import { CustomInput } from "reactstrap";
//// MODEL
import { getIndex, deleteItem, addItem, updateItem } from "../../utils/api";

import Confirm from "../../components/plugin/ConfirmCustom";

import BtnSaveFooterForm from "../../components/mikro/BtnSaveFooterForm";
import CrossModalButton from "../../components/mikro/CrossModalButton";
import toastr from "../../utils/toastr";
import { EyeIcon, EyeSlashIcon } from "../../components/mikro/Icon";
import StatusSwitch from "../../components/makro/StatusSwitch";
import {minLength,validateEmail} from "./../../utils/Validation"

export default class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      status: "ACTIVE",
      errors: null,
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.id !== this.props.id && this.props.id !== ""){
      getIndex("admin/" + this.props.id).then(({ data }) => {
        this.setState({
          name: data.name,
          username: data.username,
          email: data.email,
          status: data.status || 'ACTIVE',
        });
      });
    } else if(prevProps.id !== this.props.id && this.props.id === "")
      this.setState({
        name: "",
        status: "ACTIVE",
      });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleButton() {
    if (this.props.id !== "") {
      return (
        <BtnSaveFooterForm
          type="edit"
          reset={() => this.fReset()}
          delete={() => this.setState({ confirm: true })}
          store={(e) => this.fStore(e)}
        />
      );
    } else {
      return (
        <BtnSaveFooterForm
          type="add"
          reset={() => this.fReset()}
          store={(e) => this.fStore(e)}
        />
      );
    }
  }


  handleConfirm(value) {
    this.setState({ confirm: false });
    if (value === true) {
      this.fDelete();
    }
  }

  async fStore() {
    const data = {
      name: this.state.name,
      email: this.state.email,
      username: this.state.username,
      status: this.state.status,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
    };

    let errors = null;
    if (!minLength(data.name,3)) errors = {...errors, name : "Minimal 3 karakter!"}
    if (!minLength(data.username,8)) errors = {...errors, username : "Minimal 8 karakter!"}
    if (!minLength(data.email,8)) errors = {...errors, email : "Minimal 6 karakter!"}
    
    if (!validateEmail(data.email)) errors = {...errors, email : "Format email salah!"}

    if (this.props.id === "" && errors === null ) {
      if (!minLength(data.password,6)) errors = {...errors, password : "Minimal 6 karakter!"}
      if (!minLength(data.confirmPassword,6)) errors = {...errors, confirmPassword : "Minimal 6 karakter!"}
    }
    
    this.setState({ errors })

    if (this.props.id === "" && errors === null ) {
      addItem("admin", data).then((res) => {
        this.fResponse(res.status, res.message);
      });
      
    } else if (this.props.id !== "" && errors === null) {
      updateItem("admin/" + this.props.id, data).then((res) => {
        this.fResponse(res.status, res.message);
      });
    }
  }

  fDelete() {
    if (this.props.id !== "") {
      deleteItem("admin/" + this.props.id).then((res) => {
        this.fResponse(res.status, res.message);
      });
    }
  }

  fReset() {
    this.setState(
      {
        name: "",
        email: "",
        username: "",
        password: "",
        confirmPassword: "",
        errors: null,
      },
      () => this.props.onClose()
    );
  }

  fResponse(status, message) {
    if (status === "SUCCESS") {
      this.fReset();
    }
    toastr(status, message);
  }

  render() {
    const { name, status, password, confirmPassword, confirm, errors, showPassword, username, email } = this.state;
    const { isOpen, id } = this.props;
    return (
      <div>
        <Slider
          isOpen={isOpen}
          title={id !== "" ? "Edit Akun Admin" : "Tambah Akun Admin"}
          size="sm"
          header={<CrossModalButton reset={() => this.fReset()} />}
          footer={this.handleButton()}
        >
          <div className=" m-3">
            <div className="row">
                <div className="col-12 mb-1">
                  <div className="row">
                    <div className="col-sm-12 mb-2">
                      <label>Nama </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Nama"
                        name="name"
                        value={name}
                        onChange={(e) => this.handleChange(e)}
                      />
                      {errors &&
                        errors.name && (
                          <div className="invalid-feedback d-block">
                            {errors.name}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 mb-2">
                      <label>No. Handphone</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="0823*****"
                        value={username}
                        name="username"
                        onChange={(e) => this.handleChange(e)}
                      />
                      {errors &&
                        errors.username && (
                          <div className="invalid-feedback d-block">
                            {errors.username}
                          </div>
                        )}
                    </div>
                    <div className="col-sm-6 mb-2">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        name="email"
                        value={email}
                        onChange={(e) => this.handleChange(e)}
                      />
                      {errors &&
                        errors.email && (
                          <div className="invalid-feedback d-block">
                            {errors.email}
                          </div>
                        )}
                    </div>
                  </div>

                </div>
            </div>
            
            <StatusSwitch status={status} onChange={(status)=> this.setState({ status })} />
            
            <div className="row">
              <div className="col-sm-6 mb-2">
                <label>Password Baru</label>
                <div class="input-group m-0">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control px-2 form-control-auth"
                    name="password"
                    minLength="6"
                    maxLength="50"
                    placeholder="Password Baru"
                    onChange={(e) => this.handleChange(e)}
                    value={password}
                    autoComplete="off"
                    required
                  />
                  <div
                    class="input-group-append bg-white pointer"
                    onClick={() =>
                      this.setState({
                        showPassword: !showPassword,
                      })
                    }
                  >
                    <span class="input-group-text bg-white fs-16px  px-1-2">
                      {showPassword ? (
                        <EyeIcon />
                      ) : (
                        <EyeSlashIcon/>
                      )}
                    </span>
                  </div>
                </div>
                {errors &&
                  errors.password && (
                    <div className="invalid-feedback d-block">
                      {errors.password}
                    </div>
                  )}
              </div>
              <div className="col-sm-6 mb-2">
                <label>Ulangi Password Baru</label>
                <div class="input-group m-0">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control px-2 form-control-auth"
                    name="confirmPassword"
                    minLength="6"
                    maxLength="50"
                    placeholder="Ulangi Password Baru"
                    onChange={(e) => this.handleChange(e)}
                    value={confirmPassword}
                    autoComplete="off"
                    required
                    disabled={this.state.spinner}
                  />
                  <div
                    class="input-group-append bg-white pointer"
                    onClick={() =>
                      this.setState({
                        showPassword: !this.state.showPassword,
                      })
                    }
                  >
                    <span class="input-group-text bg-white fs-16px  px-1-2">
                      {this.state.showPassword ? (
                        <EyeIcon />
                      ) : (
                        <EyeSlashIcon/>
                      )}
                    </span>
                  </div>
                </div>
                {errors &&
                  errors.confirmPassword && (
                    <div className="invalid-feedback d-block">
                      {errors.confirmPassword}
                    </div>
                  )}
              </div>
            </div>
          </div>

        </Slider>
        <Confirm
          show={confirm}
          title="Hapus Akun"
          description={"Ingin menghapus akun admin "+name+" ?"}
          btnConfirmText="Ya, Hapus"
          onClose={()=>this.setState({ confirm: false })}
          onChange={(value) => value === true && this.handleConfirm(value)}
        />
      </div>
    );
  }
}
