import React from 'react';
import Logo from "./../../assets/logo/logo-white.png";

export default function AuthIlustration(){
    return <div 
      className="d-flex flex-column justify-content-center mx-auto p-3" 
      style={{ minHeight:'100vH',background:"linear-gradient(249.77deg, rgb(88, 185, 255) -1.99%, rgb(0 42 86) 43.52%, #133e7e 95.86%)" }}>
      <img
        className="mb-3 img-fluid mx-auto"
        src={Logo}
        alt="First slide"
        style={{
          maxWidth:"440px"
        }}
      />
  </div> 
            
}