import React, { Component, Fragment } from "react";
import "./sidebar.css";
import MenuHasSubArrow from "../../components/mikro/MenuHasSubArrow";
import { Link, Redirect } from "react-router-dom";
import {deleteSaveUser, getUser} from "../../utils/AuthUser"
import {
  DashboardIcon,
  ProdukIcon,
  PelangganIcon,
  LogOutIcon,
  CompanyIcon,
  LaporanIcon,
  EOIcon,
  ContactIcon,
  UserSettingIcon,
} from "../../components/mikro/Icon";
import Confirm from "../../components/plugin/ConfirmCustom";
import Item from "./Item";
export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: this.props.url,
      confirm: false,
      toLogin: false,
      user: getUser()
    };
  }

  handleOutsideClick(e) {
    if (this.props.orientation === "portrait")
      this.props.onChange(!this.props.show);
  }

  handleLogout() {
    this.setState({ confirm: true });
  }

  async handleConfirm(e) {
    if (e.confirm === true)
      this.setState({ toLogin: true, confirm: false }, () => deleteSaveUser());
    else this.setState({ confirm: false });
  }

  render() {
    const { menu } = this.state;

    const { orientation } = this.props;
    let show = "-300px";
    let top = "calc(4.7rem + 2px)";
    let height = "100vH";
    if (orientation === "portrait") {
      show = this.props.show ? "-300px" : "0";
      top = "0";
      height = "100vH";
    } else {
      show = this.props.show ? "0" : "-300px";
      top = "calc(4.7rem + 2px)";
      height = "calc(100vH - 4.7rem)";
    }
    return (
      <div>
        {this.state.toLogin ? <Redirect to="/" /> : ""}
        <Confirm
          title="Keluar Akun"
          description="Apakah Anda ingin keluar ?"
          btnConfirmText="Keluar"
          show={this.state.confirm}
          onClose={(e) => this.setState({ confirm:false})}
          onChange={(e) => this.handleConfirm(e)}

        />

        <div
          id="mySidebar"
          style={{
            left: show,
            top: top,
            boxShadow: "0 2px 18px 1px rgb(49 53 72 / 10%)",
            height: height,
          }}
        >
          <Item
            url={this.state.url}
            title={"Beranda"}
            link="beranda"
            icon={<DashboardIcon />}
            handleOutsideClick={() => this.handleOutsideClick()}
          />
          <Item
            url={this.state.url}
            title={"Proposal Masuk"}
            link="proposal"
            icon={<LaporanIcon />}
            handleOutsideClick={() => this.handleOutsideClick()}
          />
          <Item
            url={this.state.url}
            title={"Sponsorship"}
            link="sponsorship"
            icon={<CompanyIcon />}
            handleOutsideClick={() => this.handleOutsideClick()}
          />
          <Item
            url={this.state.url}
            title={"Event Organizer"}
            link="event-organizer"
            icon={<EOIcon />}
            handleOutsideClick={() => this.handleOutsideClick()}
          />
          <Item
            url={this.state.url}
            title={"Jenis Kegiatan"}
            link="jenis-kegiatan"
            icon={<ProdukIcon />}
            handleOutsideClick={() => this.handleOutsideClick()}
          />
          {this.state.user && this.state.user.role !== 'ADMIN' && 
          <Item
            url={this.state.url}
            title={"Kelola Admin"}
            link="admin"
            icon={<PelangganIcon />}
            handleOutsideClick={() => this.handleOutsideClick()}
          /> }
          <Item
            url={this.state.url}
            title={"Info Kontak"}
            link="info-kontak"
            icon={<ContactIcon />}
            handleOutsideClick={() => this.handleOutsideClick()}
          />
          
          <Item
            url={this.state.url}
            title={"Profil"}
            link="profil"
            icon={<UserSettingIcon />}
            handleOutsideClick={() => this.handleOutsideClick()}
          />
          <hr />
          <Link className="ember-view" onClick={() => this.handleLogout()}>
            <div
              className="d-flex flex-row"
              style={{ justifyContent: "flex-start" }}
            >
              <div className="icon">
                <LogOutIcon />
              </div>
              <div>Keluar</div>
            </div>
          </Link>
        </div>
        <div
          id="fill-sidebar"
          onClick={() => this.handleOutsideClick()}
          style={{
            visibility:
              orientation !== "portrait"
                ? "hidden"
                : this.props.show
                ? "hidden"
                : "visible",
          }}
        ></div>
      </div>
    );
  }
}
