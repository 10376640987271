import React, { Component, Fragment } from "react";

//table
import Filter from "./Filter";
import EmptyPage from "../../components/plugin/EmptyPage";

//// MODEL
import { getIndex } from "../../utils/api";

import ContentHeader from "../../components/mikro/ContentHeader";
import ReactPaginate from "react-paginate";

import ImageCover from '../../components/mikro/ImageCover'
import CorporateImgIcon from '../../assets/corporate.png'
import { CompanyIcon, DotIcon, UserSettingIcon } from "../../components/mikro/Icon";

export default class Corporate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Sponsor",
      isFormOpen: false,

      filter: {
        paginate: true,
        search: "",
      },

      id: "",
      items: {
        data: [],
        total: 0,
        current_page: 0,
        last_page: 0,
        per_page: 0,
      },
      selected: null,
    };
  }
  componentDidMount() {
    this.getIndex();
  }
  getIndex() {
    getIndex("sponsors", {
      ...this.state.filter,
      page: this.state.items.current_page,
    }).then((res) => {
      let items = {
        data: res.data || '',
        total: res.meta.total || 0,
        current_page: res.meta.current_page || 0,
        last_page: res.meta.last_page || 0,
        per_page: res.meta.per_page || 0,
      };
      this.setState({
        items: items,
      });

      window.scrollTo(0, 0);
    });
  }

  handleFilter(e) {
    let { filter } = this.state;
    this.setState({ filter:{...filter,...e}, selected:null }, () => this.getIndex());
  }

  handlePageClick = async (data) => {
    const page = data.selected >= 0 ? data.selected + 1 : 0;
    this.setState(
      {
        items: {
          ...this.state.items,
          current_page: page,
        },
        selected:null
      },
      () => this.getIndex()
    );
  };

  handleClick (sponsor) {
    this.setState({ selected: sponsor });
  }
  render() {
    const { items, title, id, selected } = this.state;
    return (
      <Fragment>
        <ContentHeader title={title}/>
        <div className="content-header row">
          <div className="content-header-left col-12 mb-1 breadcrumb-new">
            <Filter onChangeValue={(e) => this.handleFilter(e)} />
          </div>
        </div>
        <div className="content-body">
        {parseInt(this.state.items.total) === 0 ?
          <div className="mt-5">
            <EmptyPage text={true} />
          </div> : 
          <div class="row match-height">
            <div class={selected ? "col-12 col-xl-4 col-lg-5" : "col-12"}>
              <div class="table-responsive">
                <table id="recent-orders" class="table table-hover table-lg mb-0">
                  <thead className="bg-f4">
                    <tr>
                      <th >No</th>
                      <th colSpan={2}>Sponsor</th>
                    </tr>
                  </thead>
                <tbody>
                  {items.data.map((d,i)=>(
                    <tr key={d.uuid} className={selected && selected.uuid === d.uuid ? "bg-f5 pointer":"pointer"} onClick={()=>this.handleClick(d)}>
                      <td className="text-center align-middle" width="5%">{i + items.current_page}</td>
                      <td class="text-truncate p-1 border-top-0 text-center" width="10%">
                        <div class="avatar avatar-md border">
                          <ImageCover src={d.corporate_logo} fallbackSrc={CorporateImgIcon}/>
                        </div>
                      </td>
                      <td class="text-truncate pl-0 border-top-0">
                        <div class="name">{d.corporate_name || ''}</div>
                        <div class="designation font-small-2">PIC: {d.pic_name || ''} </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
                </table>
                
              </div>
              <div className="d-flex align-items-center w-100">
                <ReactPaginate
                  previousLabel={"Sebelumnya"}
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextLabel={"Selanjutnya"}
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel={"..."}
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  marginPagesDisplayed={2}
                  onPageChange={this.handlePageClick}
                  pageRangeDisplayed={items.per_page}
                  pageCount={items.last_page}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                />
              </div>
            </div>
            { selected && <div id="recent-appointments" class="col-12 col-xl-8 col-lg-7">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title m-0">Info Sponsor</h4>
                </div>
                <div class="card-body">
                  <div class="col-12">
                    <h5 class="mb-1"><CompanyIcon/>&nbsp;&nbsp;Info Perusahaan</h5>
                    <table class="table table-borderless mb-1">
                      <tbody>
                        <tr>
                          <td className="w-25">Nama Perusahaan:</td>
                          <td class="users-view-username">{selected.corporate_name || ''}</td>
                        </tr>
                        <tr>
                          <td className="w-25">Nomor Telpon:</td>
                          <td class="users-view-name">{selected.corporate_telephone || ''}</td>
                        </tr>
                        <tr>
                          <td className="w-25">E-mail:</td>
                          <td class="users-view-email">{selected.corporate_email || ''}</td>
                        </tr>
                        <tr>
                          <td className="w-25">Website:</td>
                          <td>{selected.corporate_website || ''}</td>
                        </tr>
                        <tr>
                          <td className="w-25">Wilayah Operasional:</td>
                          <td>{selected.corporate_operation_label || ''}</td>
                        </tr>
                        <tr>
                          <td className="w-25">Alamat Detail:</td>
                          <td>{selected.corporate_address || ''}</td>
                        </tr>

                      </tbody>
                    </table>
                   
                    <h5 class="mb-1"><UserSettingIcon/>&nbsp;&nbsp;Info PIC</h5>
                    <table class="table table-borderless mb-0 mb-1">
                      <tbody>
                        <tr>
                          <td className="w-25">Nama:</td>
                          <td>{selected.pic_name || ''}</td>
                        </tr>
                        <tr>
                          <td className="w-25">Nomor HP:</td>
                          <td>{selected.pic_phone_number || ''}</td>
                        </tr>
                        <tr>
                          <td className="w-25">E-mail:</td>
                          <td>{selected.pic_email || ''}</td>
                        </tr>
                        <tr>
                          <td className="w-25">Posisi di perusahaan:</td>
                          <td>{selected.pic_job}</td>
                        </tr>
                        
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div> }
          </div>}
        </div>
      </Fragment>
    );
  }
}
