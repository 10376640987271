import React, {  Fragment, useEffect, useState } from "react";
import { Link,  Redirect,    useParams } from 'react-router-dom';


import { PDFXLIcon, DateIcon, DotIcon,TrashIcon, PencilSquareIcon} from "../../components/mikro/Icon";
import Confirm from "../../components/plugin/ConfirmCustom";
import { deleteItem, getIndex } from "../../utils/api";
import toastr from "../../utils/toastr";
import ContentHeader from "../../components/mikro/ContentHeader";
import CardSponsor from "./CardSponsor";

export default function ProposalDetail () {
    const {id} = useParams();
    const [data, set_data] = useState(null);
    const [isOpenPanel, set_isOpenPanel] = useState(null);
    const [redirect, set_redirect] = useState("");
    useEffect(()=>{
      getIndex("proposal/"+id).then((res) => {
        if(res.code === 200)
          set_data(res.data);
        else if(res.code === 404)
          set_redirect("/app/daftar-proposal")
      });
    },[])
    
  
    if(redirect !== "")
    return <Redirect to={redirect}/>
    return (
      <Fragment>
        <ContentHeader
          title={data && data.title}
          subtitle="Proposal"
          path="/app/proposal"
        />
        {data && <div className="card mb-2">
          <div className="card-header px-3 pt-3 pb-1 d-flex justify-content-between">
            {data && data.title && <h3 className="font-weight-bold m-0">{data.title }</h3>}
            {data && data.status &&  
            <div 
              className="d-flex " 
              style={{
                position: 'absolute',
                top: '20px',
                right: '20px',
                backgroundColor: 'inherit',
              }}>
                {data &&  data.status === 'DRAFT' && <Link to={"/app/proposal/"+data.id+'/edit'}>
                  <button type="button" className="btn btn-icon btn-sm action-icon rounded">
                    <PencilSquareIcon/>
                  </button>
                </Link>}
              </div>}
    
          </div>
          <div className="card-body px-3 pb-3">
            {data && data.published_date && <p className="d-flex text-muted align-items-center mb-2 fs-16px">
              <DateIcon/><div>&nbsp;&nbsp;Dipublikasikan: {data.published_date}</div>
            </p>}
            {data && data.message && 
            <div className="row mb-2">
              <div className="col-md-12">
                <h5 className="font-weight-bold mb-2">Deskripsi/Pesan ke Sponsor</h5>
                <p className="mb-2 fs-16px" style={{ lineHeight: '1.8em'}}>
                <div dangerouslySetInnerHTML={ { __html: data.message } }></div></p>
              </div>
            </div>}
            { data && data.approval_status && 
            <div className="row mb-2">
              <div className="col-xl-4 col-lg-6  col-md-12">
                <h5 className="font-weight-bold mb-2">Status Approval</h5>
                <div className={"font-weight-bold fs-16px d-flex align-items-center " + (data.approval_status !== 'APPROVE' ? 'text-danger' :'text-success'  )}>
                  <DotIcon width="24" height="24"/> <span>{data.approval_status || ''}</span>
                </div>
              </div>
            </div>}
         
            { data && data.event_date && 
            <div className="row mb-2">
              <div className="col-xl-4 col-lg-6  col-md-12">
                <h5 className="font-weight-bold mb-2">Tanggal Kegiatan</h5>
                <p>{data.event_date}</p>
              </div>
              <div className="col-xl-4 col-lg-6  col-md-12">
                <h5 className="font-weight-bold mb-2">Jam Kegiatan</h5>
                <p>{data.event_time}</p>
              </div>
            </div>}

            { data && (data.event_location || data.target_participants) &&
            <div className="row mb-2">
              {data && data.event_location && <div className="col-xl-4 col-lg-6  col-md-12">
                <h5 className="font-weight-bold mb-2">Lokasi Kegiatan</h5>
                <p>{data.event_location}</p>
              </div>}
              { data && data.target_participants && <div className="col-xl-4 col-lg-6  col-md-12">
                <h5 className="font-weight-bold mb-2">Target Peserta</h5>
                <p>{data.target_participants}</p>
              </div>}
            </div>}

            { data && data.file_url && data.file_name && 
            <div className="row mb-2">
              <div className="col-xl-4 col-lg-6  col-md-12">
                <h5 className="font-weight-bold mb-2">Lampiran</h5>

                <a 
                  href={data.file_url} 
                  className=" card p-1 d-flex flex-row align-items-center"
                >
                  <div className="mr-05">
                    <PDFXLIcon width="50" height="50" />
                  </div>
                  <div className="position-relative">
                    <p>{data.file_name || ''}</p>
                  </div>
                </a>
                <small className="mt-1 text-muted">&#128712;&nbsp;Klik untuk membuka file proposal</small>
              </div>
            </div>}

            {data && data.categories && <h5 className="font-weight-bold mb-2">{data.categories.length} Jenis Kegiatan</h5>}
            {data && data.categories && <p>{data.categories.map((category,i)=>(
              <div 
                className="btn btn-outline-danger rounded mr-1" 
                style={{padding:'.4rem 1rem'}}
                key={category}>{category}</div>
              ))}
            </p>}
          </div>
        </div>}

         {data && <div className="card mb-2">
          <div className="card-header">
            <h4 className="card-title mb-0 font-weight-bold">Jenis dan Keuntungan Sponsorship</h4>
          </div>
          <div className="card-content">
            <div className="table-responsive">
              <table id="recent-orders" className="table table-hover table-xl mb-0">
                <thead>
                  <tr>
                    <th className="border-top-0" style={{width:"20%"}}>Jenis Sponsorship</th>
                    <th className="border-top-0" style={{width:"30%"}}>Supporting</th>
                    <th className="border-top-0" style={{width:"50%"}}>Value</th>
                  </tr>
                </thead>
                <tbody>
                  { data && (data.support_main_sponsor_need || data.support_main_sponsor_value) && <tr>
                    <td>Sponsor Utama</td>
                    <td>{data.support_main_sponsor_need || ''}</td>
                    <td>{data.support_main_sponsor_value || ''}</td>
                  </tr>}
                  { data && (data.support_co_sponsor_need || data.support_co_sponsor_value) && <tr>
                    <td>Sponsor Pendamping</td>
                    <td>{data.support_co_sponsor_need || ''}</td>
                    <td>{data.support_co_sponsor_value || ''}</td>
                  </tr>}
                  { data && (data.support_other_sponsor_need || data.support_other_sponsor_value) && <tr>
                    <td>Sponsor Lainnya</td>
                    <td>{data.support_other_sponsor_need || ''}</td>
                    <td>{data.support_other_sponsor_value || ''}</td>
                  </tr>}
                </tbody>
              </table>
            </div>
          </div>
        </div>}

        {data && <CardSponsor data={data} />}
   
      </Fragment>
    );
}
