
import React, { Component, Fragment } from 'react';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import CardWidgetTotal from '../../components/mikro/CardWidgetTotal';
import { UploadIcon } from '../../components/mikro/Icon';
import { getIndex } from '../../utils/api';

//// MODEL

export default class Beranda extends Component {
  constructor(props) {
    super(props)
    this.state = {
      corporate: 0,
      eo: 0,
      per_year: 0,
      category: 0
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData() {
    getIndex("widget-info").then((res) => {
      if(res.code === 200)
        this.setState({
            corporate: res && res.data && (res.data.corporate || '0'),
            per_year: res && res.data && (res.data.per_year || '0'),
            category: res && res.data && (res.data.category || '0'),
            eo: res && res.data && (res.data.eo || '0'),
        })
    });
  }

  handleFilter(e) {
    
  }

  render() {
    return (
      <Fragment>
       
        <div className="content-body">
          <div className='row mb-2'>
            <div className='col'>
              <div className='card border-left-blue border-right-blue'>
                <div className='card-header pb-0'>
                  <h4 className='font-weight-bold m-0'>Info</h4>
                </div>
                <div className='card-body'>
                  <p className='fs-16px'>Lengkapi data kategori yang dibutuhkan untuk mengoptimalkan pilihan kategori yang digunakan EO dan Sponsor.</p>

                  <p className='fs-16px'>Jangan lupa masukkan data kontak admin pada menu <Link to="/app/info-kontak">Info Kontak</Link> untuk menampilkan kontak pada bagian footer Landing Page </p>
                 
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <CardWidgetTotal title="Proposal Masuk Tahun Ini" color="info" total={this.state.per_year}/>
            <CardWidgetTotal title="Total Kategori" color="warning" total={this.state.category}/>
            <CardWidgetTotal title="Total Sponsor Terdaftar" color="danger" total={this.state.corporate}/>
            <CardWidgetTotal title="Total EO Terdaftar" color="secondary" total={this.state.eo}/>
          </div>

        </div>
      </Fragment>
    );
  }
}

