import React, { useState } from "react";
import "./../assets/css/select.css";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

import Navbar from "../parts/Navbar";
import Sidebar from "../parts/Sidebar";

import Beranda from "./Beranda";
import Proposal from "./Proposal";
import ProposalDetail from "./ProposalDetail";
import Sponsor from "./Sponsor";
import EO from "./EO";
import Kategori from "./Kategori";
import Admin from "./Admin";
import InfoKontak from "./InfoKontak";

////  pages
import NotFound from "./Others/NotFound";

import { screenOrientation } from "../utils/DeviceDetector";
import Profil from "./Profil";
import { getUser } from "../utils/AuthUser";
import Forbidden from "./Others/Forbidden";

const Backoffice = (props) => {
  const [showSidebar, set_showSidebar] = useState(true);
  const [orientation, set_orientation] = useState(screenOrientation());
  const [user, set_user] = useState(getUser());

  return (
    <React.Fragment>
      <Navbar
        url={props.match.path}
        show={showSidebar}
        onChange={(e) => set_showSidebar(e)}
      />
      <Sidebar
        orientation={orientation}
        url={props.match.path}
        show={showSidebar}
        onChange={(e) => set_showSidebar(e)}
      />
      <div
        className="app-content content pb-4"
        style={{
          backgroundColor: "#fefefe",
          marginLeft:
            orientation !== "portrait" ? (showSidebar ? "240px" : "0") : "0",
        }}
      >
        <div
          className="content-wrapper"
          style={
            orientation !== "portrait"
              ? {
                  padding: "2rem 2.5rem",
                }
              : { paddingTop: "1.3rem", paddingBottom: "1.3rem" }
          }
        >
          <div className="row">
            <div className="col-md-12">
              <Switch>
                <Route
                  exact
                  path={`${props.match.path}/beranda`}
                  component={Beranda}
                />
                <Route
                  exact
                  path={`${props.match.path}/proposal`}
                  component={Proposal}
                />
                <Route
                  exact
                  path={`${props.match.path}/proposal/:id`}
                  component={ProposalDetail}
                />
                <Route
                  exact
                  path={`${props.match.path}/jenis-kegiatan`}
                  component={Kategori}
                />
               
                <Route
                  exact
                  path={`${props.match.path}/event-organizer`}
                  component={EO}
                />
                <Route
                  exact
                  path={`${props.match.path}/sponsorship`}
                  component={Sponsor}
                />
                <Route
                  exact
                  path={`${props.match.path}/admin`}
                  component={user.role === 'ADMIN' ? Forbidden : Admin}
                />
                <Route
                  exact
                  path={`${props.match.path}/info-kontak`}
                  component={InfoKontak}
                />
                <Route
                  exact
                  path={`${props.match.path}/profil`}
                  component={Profil}
                />
                <Route component={NotFound} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Backoffice;
