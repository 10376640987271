import React, { Component } from "react";
import Slider from "react-slide-out";

// import { CustomInput } from "reactstrap";
//// MODEL
import { getIndex, deleteItem, addItem, updateItem } from "../../utils/api";

import Confirm from "../../components/plugin/ConfirmCustom";

import BtnSaveFooterForm from "../../components/mikro/BtnSaveFooterForm";
import CrossModalButton from "../../components/mikro/CrossModalButton";
import toastr from "../../utils/toastr";
import StatusSwitch from "../../components/makro/StatusSwitch";


export default class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      status: "ACTIVE",
      confirm: false,
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.id !== this.props.id && this.props.id !== "") {
      getIndex("category/" + this.props.id).then(({ data }) => {
        this.setState({
          name: data.name,
          status: data.status || 'ACTIVE',
        });
      });
    } else if(prevProps.id !== this.props.id && this.props.id === "")
      this.setState({
        name: "",
        status: "ACTIVE",
      });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleButton() {
    if (this.props.id !== "") {
      return (
        <BtnSaveFooterForm
          type="edit"
          reset={() => this.fReset()}
          delete={() => this.setState({ confirm: true })}
          store={(e) => this.fStore(e)}
        />
      );
    } else {
      return (
        <BtnSaveFooterForm
          type="add"
          reset={() => this.fReset()}
          store={(e) => this.fStore(e)}
        />
      );
    }
  }


  handleConfirm(value) {
    this.setState({ confirm: false });
    if (value === true) {
      this.fDelete();
    }
  }

  async fStore() {
    const data = {
      name: this.state.name,
      status: this.state.status,
    };
    if (this.props.id === "") {
      addItem("category", data).then((res) => {
        this.fResponse(res.status, res.message);
      });
    } else if (this.props.id !== "") {
      updateItem("category/" + this.props.id, data).then((res) => {
        this.fResponse(res.status, res.message);
      });
    }
  }

  fDelete() {
    if (this.props.id !== "") {
      deleteItem("category/" + this.props.id).then((res) => {
        this.fResponse(res.status, res.message);
      });
    }
  }

  fReset() {
    this.setState(
      {
        name: "",
        nomor_rekening: "",
        name_bank: "",
        is_default: false,
      },
      () => this.props.onClose()
    );
  }

  fResponse(status, message) {
    if (status === "SUCCESS") {
      this.fReset();
    }
    toastr(status, message);
  }

  render() {
    const { name, status, confirm } = this.state;
    const { title, isOpen, id } = this.props;
    return (
      <div>
        <Slider
          isOpen={isOpen}
          title={id !== "" ? "Edit " + title : "Tambah " + title}
          size="sm"
          header={<CrossModalButton reset={() => this.fReset()} />}
          footer={this.handleButton()}
        >
          <div className="row m-3">
            <div className="col-12 ">
              
              <div className="form-group">
                <label>Kategori</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Masukkan kategori"
                  name="name"
                  value={name}
                  onChange={(e) => this.handleChange(e)}
                />
              </div>

              <StatusSwitch status={status} onChange={(value)=>this.setState({ status: value })}/>
      
            </div>
          </div>
        </Slider>
        <Confirm
          show={confirm}
          title="Hapus Jenis Kegiatan"
          description={"Ingin menghapus jenis kegiatan "+name+" ?"}
          btnConfirmText="Ya, Hapus"
          onClose={()=>this.setState({ confirm: false })}
          onChange={(value) => value === true && this.handleConfirm(value)}
        />
      </div>
    );
  }
}
