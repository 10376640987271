import React, { Component, Fragment } from "react";

import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  WaIcon,
} from "../../components/mikro/Icon";

import { getIndex, updateItem } from "../../utils/api";
import ContentHeader from "../../components/mikro/ContentHeader";
import toastr from "../../utils/toastr";

export default class InfoKontak extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '' ,
      postal_code: '' ,
      telephone: '' ,
      email: '' ,
      sm_whatsapp: '' ,
      sm_twitter: '' ,
      sm_facebook: '' ,
      sm_instagram: '' ,
    };
  }
  componentDidMount() {
    getIndex("contact-setting").then((res) => {
      this.setState({
        address: res.data.address || '',
        postal_code: res.data.postal_code || '',
        telephone: res.data.telephone || '',
        email: res.data.email || '',
        sm_whatsapp: res.data.sm_whatsapp || '',
        sm_twitter: res.data.sm_twitter || '',
        sm_facebook: res.data.sm_facebook || '',
        sm_instagram: res.data.sm_instagram || '',
      });
    });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  fResponse(status, message) {
   
  }

  handleSubmit(e) {
    e.preventDefault();
    updateItem("contact-setting", {
      address: this.state.address,
      postal_code: this.state.postal_code,
      telephone: this.state.telephone,
      email: this.state.email,
      sm_whatsapp: this.state.sm_whatsapp,
      sm_twitter: this.state.sm_twitter,
      sm_facebook: this.state.sm_facebook,
      sm_instagram: this.state.sm_instagram,
    }).then((res) => {
      toastr(res.status, res.message);
    });
  }

  render() {
    const {
      address,
      postal_code,
      telephone,
      email,
      sm_whatsapp,
      sm_twitter,
      sm_facebook,
      sm_instagram,
    } = this.state;
    return (
      <Fragment>
        <ContentHeader
          title="Info Kontak"
        />
        <div className="content-body">
          <form onSubmit={(e) => this.handleSubmit(e)}>
            <div className="row p-1">
              <div className="col-12 mb-1">
                <div className="row mb-2">
                  <div className="col-md-6">
                    <label>Alamat</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Alamat"
                      name="address"
                      value={address}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>
                  <div className="col-md-4 ">
                    <label>Kode Pos</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Kode POS"
                      name="postal_code"
                      value={postal_code}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <label>Telpon</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="08123992****"
                      name="telephone"
                      value={telephone}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>
                  <div className="col-md-6 ">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      name="email"
                      value={email}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row p-1">
              <div className="col-12">
                <label className="mb-1">MEDIA SOSIAL</label>
                <div className="form-group">
                  <div className="input-group mb-1">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <WaIcon />
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Masukkan Nomor Whatsapp *contoh: 628123xxxx"
                      name="sm_whatsapp"
                      value={sm_whatsapp}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="input-group mb-1">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <TwitterIcon />
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Masukkan Link Profil Twitter"
                      name="sm_twitter"
                      value={sm_twitter}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="input-group mb-1">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <FacebookIcon />
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Masukkan Link Profil Facebook"
                      name="sm_facebook"
                      value={sm_facebook}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="input-group mb-1">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <InstagramIcon />
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Masukkan Link Profil Instagram"
                      name="sm_instagram"
                      value={sm_instagram}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row p-1">
              <div className="col-12">
                <div className="form-group text-right">
                  <button type="submit" className="btn btn-info">
                    Simpan
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}
